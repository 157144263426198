import "./Sidebar.scss";
import meiLogoSmall from "../../assets/global/meiLogoSmall.svg";
import chatDarkTheme from "../../assets/ChatHistory/chatDarkTheme.svg";
import secretKeyDark from "../../assets/ChatHistory/secretKeyDark.svg";
import apiDark from "../../assets/ChatHistory/apiDark.svg";
import reload from "../../assets/ChatHistory/reload.svg";
import scriptLogo from "../../assets/ChatHistory/scriptLogo.svg";
import copyDark from "../../assets/ChatHistory/copyDark.svg";
import FAQ from "../../assets/ChatHistory/FAQ.svg";
import Modal from 'react-bootstrap/Modal';
import { Tooltip } from 'react-tooltip'
import { useEffect, useMemo, useRef, useState } from "react";
import { axiosInstance } from '../../Utilities/Interceptor/Interceptor'
import { API_END_POINTS, ROUTES } from "../../Constants/Constants";
const Sidebar = () => {
    const [show, setShow] = useState(false);
    const [showSecretModal, setShowSecretModal] = useState(false);
    const [showCopiedTip, setShowCopiedTip] = useState(false);
    const [showCopiedTipKey, setShowCopiedTipKey] = useState(false);
    const [apiKey, setapiKey] = useState('');
    const [apiKeyScript, setapiKeyScript] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseSecretModal = () => setShowSecretModal(false);
    const handleShowSecretModal = () => setShowSecretModal(true);

    const startCopiedTimer = () => {
        setInterval(() => { setShowCopiedTip(false) }, 2000)
    }
    const startCopiedTimerKey = () => {
        setInterval(() => { setShowCopiedTipKey(false) }, 2000)
    }

    useEffect(()=>{
          fetchApiTrigger()
    },[])

    const fetchApiTrigger = async () => {
        await axiosInstance.get('api_key').then((response: any) => {
         setapiKey(response.data.data.api_key)
         setapiKeyScript(response.data.data.bundle_key)
        })
    }
    function downloadFile(name:any,filetext:any) {
        const link = document.createElement('a');
        link.download = name;
        link.href = 'data:text/html;charset=utf-8,' + encodeURI(filetext);
        link.click();
      }
    const generateAPIkeys = async () => {
        await axiosInstance.post('generate_keys',{}).then((response: any) => {
         setapiKey(response.data.data.api_key)
         setapiKeyScript(response.data.data.bundle_key)
        })
    }
    return <section className="sidebar-wrapper">
        <div className="h-50 d-flex flex-column justify-content-between">
            <div className="nav-logo-container">
                <img src={meiLogoSmall} alt="" className="mei-logo-small nav-logo " />
            </div>
            <div className="main-nav-logos-container d-flex flex-column">
                <div className="nav-logo-container bg-mild-grey-hover bg-light-grey">
                    <img src={chatDarkTheme} alt="" className="nav-logo" />
                </div>
                <div className="nav-logo-container bg-mild-grey-hover bg-light-grey" onClick={handleShowSecretModal}>
                    <img src={secretKeyDark} alt="" className="nav-logo" />
                </div>
                {/* <div className="nav-logo-container bg-mild-grey-hover" onClick={handleShow}>
                    <img src={FAQ} alt="" className="nav-logo" />
                </div> */}
            </div>
        </div>


        <Modal show={showSecretModal} size="lg" centered onHide={handleCloseSecretModal} className="secret-key-wrapper">
            <Modal.Header closeButton className="bg-light-grey font-color-extra-light-grey font-md">
                Download as
            </Modal.Header>
            <Modal.Body className="bg-light-grey font-color-extra-light-grey">
                <div className="d-flex mb-2 mb-sm-4">
                    <img src={apiDark} alt="API-secret-key" className="api-secret-logo" />
                    <div className="d-flex justify-content-between w-100">
                        <div className="d-flex align-items-center">
                            <h4 className="font-sm mb-0">API Access key</h4>
                            <img src={reload} alt="regenerate-secret-key" className="reload-icon" width={100} height={100} onClick={() => generateAPIkeys()} />
                        </div>
                        <a data-tooltip-id="copySecretKey" onClick={() => setShowCopiedTip(true)}>
                            <img src={copyDark} alt="copy-secret-key" className="reload-icon" width={100} height={100} onClick={() => navigator.clipboard.writeText(apiKey)} />
                            <Tooltip
                                id="copySecretKey"
                                place="right"
                                content="Copied successfully"
                                isOpen={showCopiedTip}
                                afterShow={startCopiedTimer}
                            />
                        </a>
                    </div>
                </div>
                <div className="d-flex flex-column secret-key-download-container align-items-start">
                    <p className="secret-key font-sm">{apiKey}</p>
                    <button type="button" className="download-key font-color-white bg-dark-grey text-center "
                     onClick={() => downloadFile('APIKEY FILE',apiKey)} >Download</button>
                </div>
                <div className="d-flex mb-2 mt-2 mb-sm-4 mt-sm-4">
                    <img src={scriptLogo} alt="API-secret-key" className="api-secret-logo" />
                    <div className="d-flex justify-content-between w-100">
                        <div className="d-flex align-items-center">
                            <h4 className="font-sm mb-0">Script</h4>
                            <img src={reload} alt="regenerate-secret-key" className="reload-icon" width={100} height={100} />
                        </div>
                        <a data-tooltip-id="copySecretApiKey" onClick={() => setShowCopiedTipKey(true)}>
                            <img src={copyDark} alt="copy-secret-key" className="reload-icon" width={100} height={100} onClick={() => navigator.clipboard.writeText(`<div id="WidgetMei"></div> \n ${apiKeyScript}`)} />
                            <Tooltip
                                id="copySecretApiKey"
                                place="right"
                                content="Copied successfully"
                                isOpen={showCopiedTipKey}
                                afterShow={startCopiedTimerKey}
                            />
                        </a>
                    </div>
                </div>
                <div className="d-flex flex-column secret-key-download-container align-items-start">
                    <p className="secret-key font-sm">{`<div id="WidgetMei"></div> \n ${apiKeyScript}`}</p>
                    <button type="button" className="download-key font-color-white bg-dark-grey text-center "  onClick={() => downloadFile('Script FILE',`<div id="WidgetMei"></div> \n ${apiKeyScript}`)}>Download</button>
                </div>
            </Modal.Body>
        </Modal>


        <Modal show={show} onHide={handleClose} fullscreen={true} className="bg-light-grey faq-wrapper">
            <Modal.Header closeButton className="bg-light-grey">
            </Modal.Header>
            <Modal.Body className="bg-light-grey d-flex justify-content-center font-color-extra-light-grey">
                <div className="faq-contents-container">
                    <h2 className="faq-title mb-4 font-lg">Frequently Asked Questions</h2>
                    <p className="faq-description font-exsm">We can prime a large language model (LLM) to behave in a certain way using a prompt. A prompt is a string of text that contains examples of inputs and outputs for the desired task, and it helps the model recognize how it should respond to novel inputs.The table below shows how we primed the LLM to generate words that begin with a given letter and also pertain to a given topic or domain. The format of this table is adapted from MakerSuite, which is a platform that makes it easy to build and experiment with LLM prompts. To learn more about MakerSuite, head here.</p>
                    <div className="faq-question-answers-wrapper">

                        <table className="faq-question-answers font-thin">
                            <tbody>
                                <tr>
                                    <td>music terminology</td>
                                    <td>tempo, tune, tone, treble, timbre, tablature, triad, tremolo, track, toccata, trill</td>
                                </tr>
                                <tr>
                                    <td>music terminology</td>
                                    <td>tempo, tune, tone, treble, timbre, tablature, triad, tremolo, track, toccata, trill</td>
                                </tr>
                                <tr>
                                    <td>music terminology</td>
                                    <td>tempo, tune, tone, treble, timbre, tablature, triad, tremolo, track, toccata, trill</td>
                                </tr>
                                <tr>
                                    <td>music terminology</td>
                                    <td>tempo, tune, tone, treble, timbre, tablature, triad, tremolo, track, toccata, trill</td>
                                </tr>
                                <tr>
                                    <td>music terminology</td>
                                    <td>tempo, tune, tone, treble, timbre, tablature, triad, tremolo, track, toccata, trill</td>
                                </tr>
                                <tr>
                                    <td>music terminology</td>
                                    <td>tempo, tune, tone, treble, timbre, tablature, triad, tremolo, track, toccata, trill</td>
                                </tr>
                                <tr>
                                    <td>music terminology</td>
                                    <td>tempo, tune, tone, treble, timbre, tablature, triad, tremolo, track, toccata, trill</td>
                                </tr>
                                <tr>
                                    <td>music terminology</td>
                                    <td>tempo, tune, tone, treble, timbre, tablature, triad, tremolo, track, toccata, trill</td>
                                </tr>
                                <tr>
                                    <td>music terminology</td>
                                    <td>tempo, tune, tone, treble, timbre, tablature, triad, tremolo, track, toccata, trill</td>
                                </tr>
                                <tr>
                                    <td>music terminology</td>
                                    <td>tempo, tune, tone, treble, timbre, tablature, triad, tremolo, track, toccata, trill</td>
                                </tr>
                                <tr>
                                    <td>music terminology</td>
                                    <td>tempo, tune, tone, treble, timbre, tablature, triad, tremolo, track, toccata, trill</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </section>
}

export default Sidebar;