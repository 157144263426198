import {
  GetSessionStorageData,
  SetSessionStorageData,
  nullUndefinedEmptyCheck,
} from "../Helpers/Helper";
import axios from "axios";
import {
  API_END_POINTS,
  AUTHENTICATION,
  ROUTES,
} from "../../Constants/Constants";
import { store } from "../../Redux/Store/Store";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { setLoaderStatus } from "../../Redux/Reducer/Reducer";
/**Global axios instance */
const axiosInstance = axios.create({
  baseURL: 'https://web-backend-service-fzrkutbmyq-uc.a.run.app/',
});

/**API request interceptor */
axiosInstance.interceptors.request.use(
  (config: any): any => {
    console.log(config.url.includes('query?requery'), config.url.includes('history_list'), config.url.includes('prompt_question'))
    if (config.url.includes('query?requery') || config.url.includes('history_list') || config.url.includes('prompt_question')) {
      store.dispatch(setLoaderStatus(false));
    }
    else {
      store.dispatch(setLoaderStatus(true));
    }
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      config.headers["Authorization"] = "Bearer " + `${accessToken}`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error: any): any => {
    return Promise.reject(error);
  }
);

/**API response interceptor */
axiosInstance.interceptors.response.use(
  (response: any): any => {
    store.dispatch(setLoaderStatus(false));

    return response;
  },
  (error: any): any => {
    store.dispatch(setLoaderStatus(false));
    console.log(error,'error',error?.response?.status)
    if (error?.response?.status === 401) {
      sessionStorage.removeItem('accessToken')
      return (
        <>
          <Navigate to={ROUTES.DOMAIN_FIELDS} />
        </>
      );
      // const refreshToken = GetSessionStorageData(
      //   AUTHENTICATION.SHARED.REFRESH_TOKEN
      // );
      // if (nullUndefinedEmptyCheck(refreshToken)) {
      //   let payload: any = {
      //     refresh: refreshToken,
      //   };
      //   axiosInstance
      //     .post(API_END_POINTS.REFRESH_TOKEN, payload)
      //     .then((response: any) => {
      //       if (
      //         response.data[AUTHENTICATION.SHARED.STATUS] ===
      //         AUTHENTICATION.SHARED.TRUE
      //       ) {
      //         SetSessionStorageData(
      //           AUTHENTICATION.SHARED.REFRESH_TOKEN,
      //           response.data.access
      //         );
      //       }
      //     });
      // } else {
      // }
    } else {
    }
    return Promise.reject(error);
  }
);

export { axiosInstance };
