import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { searchHistoryData, tryLinks } from "../../Constants/Constants";
import "./CapabilitiesTryLinks.scss";
import capabilities from "../../assets/ChatHistory/capabilities.png";
import Accordion from 'react-bootstrap/Accordion';
import ContactUs from "./ContactUs";


const CapabilitiesTryLinks = () => {
    const [capabilitiesActiveIndex, setCapabilitiesActiveIndex] = useState('');
    const [capabilitiesHeight, setCapabilitiesHeight] = useState(0);
    const tryLinksRef = useRef<any>(null);
    const [initialMove, setInitialMove] = useState(true);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        updateCapabilitiesHeight();
        window.addEventListener("resize", updateCapabilitiesHeight);
        return () => window.removeEventListener("resize", updateCapabilitiesHeight);
    }, []);

    useEffect(() => {
        updateCapabilitiesHeight();
    }, [tryLinksRef.current])

    const updateCapabilitiesHeight = () => {
        if (tryLinksRef.current && window.matchMedia("(min-width: 1199.98px)")) setCapabilitiesHeight(tryLinksRef.current.offsetHeight + 70)
    }
    const slickSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 10000,
        adaptiveHeight: true,
        initialSlide: 0,
        onReInit: () => {
            if (document.readyState === 'complete' && initialMove) {
                setTimeout(() => {
                    setCapabilitiesActiveIndex('0')
                    setInitialMove(false)
                }, 1000);
            }
        },
        beforeChange: (prevIndex: any, newIndex: any) => {
            setCapabilitiesActiveIndex(`${newIndex}`)
        }
    }
    return <section className="capabilities-try-links-container d-flex flex-column justify-content-between">
        <div className="capabilities-wrapper bg-light-grey" style={{ height: `calc(100vh - ${capabilitiesHeight}px` }}>
            {/* <h5 className="capabilities-title font-color-white font-body">Capabilities</h5>
            <Accordion className="capabilities-container h-100" activeKey={capabilitiesActiveIndex}>
                <>
                    <Slider {...slickSettings}>
                        {searchHistoryData.map((searchHistoryElements: any, index: any) => {
                            return (
                                <div key={index}>
                                    <Accordion.Item key={index} eventKey={index.toString()} className={`capabilities font-color-white bg-dark-grey ${index === 0 ? 'initial-slider' : ''}`}>
                                        <Accordion.Header><div className="font-body">{searchHistoryElements.title}</div></Accordion.Header>
                                        <Accordion.Body className="font-exsm">{searchHistoryElements.desc}</Accordion.Body>
                                    </Accordion.Item>
                                </div>
                            )
                        })}
                    </Slider>
                </>
            </Accordion> */}
            <div className="d-flex flex-column capabilities-field-contents h-100">
                <div className="capabilities-img-container position-relative">
                    <img src={capabilities} alt="" className="capabilities-img  " />
                </div>
                <p className="position-relative font-color-extra-light-grey"><strong><i>To believe in the power of AI Even before we were an AI company</i></strong>, we were a people-first technology company. Our vision is to be an agent and a catalyst that takes technology to every corner of the world. We predict that the biggest problem for enterprises in the age of AI would not be the disruptive nature of the technology but rather an inadequate vision for it, not allowing them to harness AI to its fullest.<br /><br />As our team achieves new milestones every day at MEI INC, we couldn’t help but wonder how fast the world would think and act if it was powered by a truly intelligent conversational AI. A future built on such a powerful piece of technology would set the stage for holistic growth for not just the people and brands, but also increase the quality of life for all- shifting focus toward crucial matters in hand.</p>
            </div>
        </div>
        <div ref={tryLinksRef} className="try-links-wrapper bg-light-grey font-color-white">
            {/* <h5 className="try-title font-body">Also try</h5> */}
            <div className="d-flex flex-wrap justify-content-start align-items-start align-content-start try-link-chips-wrapper">
                <a className="try-links-chips bg-dark-grey font-exsm font-color-extra-light-grey" href="https://www.meii.ai/insights/" target="_blank" rel="noopener">Insights</a>
                <a className="try-links-chips bg-dark-grey font-exsm font-color-extra-light-grey" href="https://www.meii.ai/aboutus" target="_blank" rel="noopener">About</a>
                <a className="try-links-chips bg-dark-grey font-exsm font-color-extra-light-grey">Contact Us</a>
                {/* onClick={()=>setOpenModal(true)} */}
                {/* {tryLinks.map((tryLinksElements: any, index: any) => {
                    return (<div key={index} className="try-links-chips bg-dark-grey font-exsm">{tryLinksElements}</div>)
                })} */}
            </div>
            <div className="d-flex justify-content-end gap-2 mt-4">
                <a className="social-icons instagram" href="https://www.instagram.com/meiaiinc/" target="_blank" rel="noopener"></a>
                <a className="social-icons twitter" href="https://twitter.com/MeiAiInc" target="_blank" rel="noopener"></a>
                <a className="social-icons linkedin" href="https://www.linkedin.com/company/mei-ai-inc/" target="_blank" rel="noopener"></a>
            </div>
        </div>
        <ContactUs 
         hideModal={()=>setOpenModal(false)}
         modalOpen={openModal}
        />
    </section>
}

export default CapabilitiesTryLinks;