import { configureStore } from "@reduxjs/toolkit";
import { chatButtonEnableReducer, chatButtonReducer, historyUpdateReducer, loaderUpdateReducer } from "../Reducer/Reducer";

/**Store configuration */
export const store = configureStore({
  reducer: { historyUpdateReducer, loaderUpdateReducer,chatButtonReducer,chatButtonEnableReducer }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
