import AWS from "aws-sdk";

/** store data in session storage */
export const SetSessionStorageData = (sessionKey: string, payload: any) => {
  return sessionStorage.setItem(sessionKey, payload);
};

/** get data from session storage */
export const GetSessionStorageData = (sessionKey: string) => {
  return sessionStorage.getItem(sessionKey);
};
/** check for null, undefined and empty value */
export const nullUndefinedEmptyCheck = (data: any) => {
  return data !== null && data !== undefined && data !== "";
};
export const nullUndefinedEmptySpaceCheck = (inputdata: any) => {
  let data = inputdata?.trim()
  return data !== null && data !== undefined && data !== "";
};
export const checkHistoryFlow =(data:any)=>{
 return (data !== null && data !== undefined && data === 'History')? true : false
}
/** check for null value */
export const nullCheck = (data: any) => {
  return data !== null;
};

/* To clear session storage */
export const clearSessionStorage = () => {
  return sessionStorage.clear();
};

/**Upload files to S3 bucket */
export const uploadFileToS3 = (file: any) => {
  return new Promise((resolve, reject) => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      region: process.env.REACT_APP_S3_REGION,
    });

    const s3 = new AWS.S3();
    const params: any = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: file.name,
      Body: file,
      ACL: "public-read",
      ContentType: file?.type,
    };

    s3.upload(params, (err: any, data: any) => {
      if (err) {
        console.error("Error uploading file:", err);
        reject(err);
      } else {
        resolve(data.Location);
      }
    });
  });
};

/**empty length check */
export const checkEmpty = (data: any): boolean => {
  return data.length !== 0;
};
