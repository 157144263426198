import React, { useEffect, useRef, useState } from "react"
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal } from "antd";

interface Props {
    hideModal: () => void;
    modalOpen: boolean;
}

const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const ContactUs = ({
    hideModal,
    modalOpen
}: Props) => {

    const ref = useRef<any>()

    useEffect(() => {
        ref?.current?.setErrors({});
        ref?.current?.resetForm();
    }, [modalOpen])

    const initialValues = {
        email: "",
        first_name: "",
        last_name: "",
        company_name: "",
        mobile_no: "",
        captcha: "",
        terms: false,
        country: ""
    }

    // validation schema

    const LoginSchema = Yup.object({
        email: Yup.string().required("Email is required").email("Enter valid email").matches(EmailRegex, "Enter valid email"),
        first_name: Yup.string().required("First name is required").matches(/^\S/, "Starting whitespace not allowed"),
        last_name: Yup.string().required("Last name is required").matches(/^\S/, "Starting whitespace not allowed"),
        company_name: Yup.string().required("Company name is required").matches(/^\S/, "Starting whitespace not allowed"),
        mobile_no: Yup.string().required("Phone number is required").min(8, "Phone number has a min length of 8 numbers").max(14, "Phone number has a max length of 14 numbers"),
        captcha: Yup.string().required("Captcha  is required"),
        terms: Yup.bool().oneOf([true], 'You need to accept the terms and conditions'),
    })

    function onChange(value: any) {
        ref.current.setFieldValue("captcha", value)
    }

    const submitForm = (values: any, resetForm: any) => {
       
    }
    return (
        <>
            <Modal
                bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)',background:"#252628" }}
                open={modalOpen}
                title=" Free AI Assessment"
                okText={"Submit"}
                cancelText={"Cancel"}
                onOk={hideModal}
                onCancel={hideModal}
                style={{ top: 20, zIndex: 999999 }}
                className="contactus"
                footer={[
                    <div className="d-flex  justify-end">
                        <button
                            type="submit"
                            form="myForm1" key="submit"
                            className="contactus_submit mei-dark bg-dark-grey border-0"
                        >
                            Submit
                        </button>
                    </div>
                ]}
            >

                <Formik
                    initialValues={initialValues}
                    validationSchema={LoginSchema}
                    onSubmit={(values, { resetForm }) => {
                        submitForm(values, resetForm)
                    }}
                    innerRef={ref}
                >
                    {({
                        handleBlur,
                        handleChange,
                        values,
                        setFieldValue,
                    }) => (
                        <Form className="" id="myForm1">
                            <div className="p-4 modal-body">
                                <div className="">
                                    <label className="description">Set a free AI assessment call with our team by completing the following details.</label>
                                    <div>
                                        <label className="mb-2 description mt-3">Business Email <span className="">*</span></label>

                                        <input
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            className="domain-field input" />
                                        <ErrorMessage name="email" component="div" className="error-message" />

                                    </div>

                                    <div>
                                        <label className="mb-2 description mt-3">First Name <span className="">*</span></label>

                                        <input
                                            type="text"
                                            name="first_name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.first_name}
                                             className="domain-field input" />
                                        <ErrorMessage name="first_name" component="div" className="error-message" />

                                    </div>

                                    <div>
                                        <label className="mb-2 description mt-3">Last Name <span className="">*</span></label>

                                        <input
                                            type="text"
                                            name="last_name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.last_name}
                                             className="domain-field input" />
                                        <ErrorMessage name="last_name" component="div" className="error-message" />

                                    </div>

                                    <div>
                                        <label className="mb-2 description mt-3">Company Name <span className="">*</span></label>

                                        <input
                                            type="text"
                                            name="company_name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.company_name}
                                             className="domain-field input" />
                                        <ErrorMessage name="company_name" component="div" className="error-message" />

                                    </div>
                                    <div>
                                        <label className="mb-2 description mt-3">Phone <span className="">*</span></label>

                                        <PhoneInput
                                            country={'us'}
                                            onBlur={handleBlur}
                                            value={values.mobile_no}
                                            inputClass="domain-field input"
                                            onChange={(value, data: any) => {
                                                ref?.current?.setFieldValue("mobile_no", `+${value}`)
                                                ref?.current?.setFieldValue("country", data?.name)
                                            }
                                            }
                                        // onChange={phone => setFieldValue("mobile_no", `+${phone}`)}
                                        />
                                        <ErrorMessage name="mobile_no" component="div" className="error-message" />

                                    </div>


                                    <div className="d-flex justify-content-between mt-3">
                                        <div className="d-flex items-start">
                                            <div className="d-flex align-items-center">
                                                <input id="remember" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={values.terms} name="terms" className="" />
                                            </div>
                                            <label className="ms-2 description">
                                                I agree all terms and conditions.
                                            </label>
                                        </div>

                                    </div>
                                    <ErrorMessage name="terms" component="div" className="error-message" />

                                    <div>
                                        <label className="mb-2 description mt-3">Captcha <span className="">*</span></label>

                                        <ReCAPTCHA
                                           className="recapta-container" sitekey='6Ld5i1EpAAAAAG-xbIm90nQzMsMEWUIy7YjNnSy9' style={{ background: "transparent" }} 
                                            onChange={onChange}
                                        />
                                        <ErrorMessage name="captcha" component="div" className="error-message" />

                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>

            </Modal>
        </>
    )
};

export default ContactUs;
