import { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import completed from "../../assets/DomainProgress/completed.svg"
import "./ProgressStatus.scss";
import { API_END_POINTS, AUTHENTICATION, ROUTES, progressData } from "../../Constants/Constants";
import { axiosInstance } from '../../Utilities/Interceptor/Interceptor'
import { io } from "socket.io-client";
import { nullUndefinedEmptyCheck,SetSessionStorageData } from "../../Utilities/Helpers/Helper";
//import { socket } from '../../socket'
const DataProgress = () => {
    let { id } = useParams();
    // const socket = io('https://meii-redis-socket-fzrkutbmyq-uc.a.run.app', {
    //     transports: ['websocket'], reconnectionAttempts: 20,
    //     reconnectionDelay: 5000
    // })
    const navigate = useNavigate();
    const [progressIndex, setProgressIndex] = useState(0);
    const [getStatusprogress, setStatusprogress] = useState<any>([])
    const progressSlickRef = useRef<any>(null);
    const toggleProgressActive = (index: any) => {
        setProgressIndex(index);
        progressSlickRef.current.slickGoTo(index);
    }
    const getVerifyLink = async (id:any) => {
        axiosInstance.get(`verify_link/?signed_link=${id}`).then((response: any) => {
            if (response?.data.status_code == API_END_POINTS.status_code) {
                SetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN, response?.data.access_token)
                sessionStorage.setItem('weblink_id', response?.data.weblink_id);
                sessionStorage.setItem('weblink_domain', response?.data.weblink_name);
                getStatusData()
            }else{
                if (response?.data.status_code == 400) {  
                    navigate(ROUTES.DOMAIN_FIELDS)
                }
            }
        },(error:any)=>{

        })
    }
    useEffect(() => {
        if(nullUndefinedEmptyCheck(id)){
            getVerifyLink(id)
        }
    }, []);
   

    const slickSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        adaptiveHeight: true,
        arrows: false,
        afterChange: (index: any) => { toggleProgressActive(index) },
        appendDots: () => (
            <div>
                <div className="navigation-dots-container">
                    <div className="nav-dots" onClick={() => toggleProgressActive(0)}></div>
                    <div className="nav-dots" onClick={() => toggleProgressActive(1)}></div>
                    <div className="nav-dots" onClick={() => toggleProgressActive(2)}></div>
                    <div className="nav-dots" onClick={() => toggleProgressActive(3)}></div>
                    <div className={`nav-dots-active ${progressIndex === 0 ? 'nav-dot-top' : progressIndex === 1 ? 'nav-dot-middle' : progressIndex === 2 ? 'nav-dot-center' : 'nav-dot-bottom'}`}></div>
                </div>
            </div>
        )
    }
    const getStatusData = async () => {
        axiosInstance.get('status_links').then((response: any) => {
            if (response) {
                if (response.data.data.length > 0) {
                    dataFormated(response.data.data)

                } else {
                    setStatusprogress([])
                }
            }
        })
    }

    const dataFormated =async (response:any) => {
        const sortedarray = response.sort((a:any,b:any) => {
            return (a.process - b.process)});
        setStatusprogress(sortedarray)
        const allCompleted = response.every((isStatus: any) => isStatus.process_status === 'Completed');
        if(allCompleted){
            navigate(`${ROUTES.CHAT_CONTENT_INITIAL}`)   
            }else{
            navigate(ROUTES.DATA_PROGRESS)
            }
      
    }
    return <>
       <section className="progress-contents-wrapper">
            <Slider ref={progressSlickRef} {...slickSettings}>
                <div>
                    <div className="progress-wrapper-box">
                        <div className="progress-wrapper">
                            <h4 className=" font-md font-color-grey">Please check your inbox for the magic link</h4>
                            <p className="font-exsm progress-title">Upon clicking on this link, you can access our platform and the model you requested. - <b>"{sessionStorage.getItem('weblink_domain')}"</b></p>
                            <ol className="progress-stepper-wrapper">
                                {getStatusprogress.map((progressDataElement: any, index: any) => {
                                    return (
                                        <li key={index} className="progress-stepper-container d-flex">
                                            <p className="progress-stepper-label font-thin font-color-light-grey">Step{' '}{index < 11 ? '0' : ''}{index + 1}</p>
                                            <div className="progress-bar-icon-container d-flex flex-column align-items-center">
                                                {progressDataElement.process_status === 'Pending' && <div className="pending-icon"></div>}
                                                {progressDataElement.process_status === 'In-Progress' && <div className="in-progress-icon"></div>}
                                                {progressDataElement.process_status === 'Completed' && <img src={completed} alt="" className="completed-status" />}
                                                {progressDataElement.process_status === 'Failure' && <div className="failed-icon"></div>}
                                                {index + 1 !== progressData.length && <div className="progress-bar-container"></div>}
                                            </div>
                                            <div className="d-flex flex-column stepper-label-status-container">
                                                <h5 className="stepper-label font-body font-color-grey">{progressDataElement.status_name}</h5>
                                                <p className={`stepper-status font-thin mb-0 font-color-light-grey ${progressDataElement.process_status === 'Completed' ? 'font-color-completed-status' : ''}${progressDataElement.process_status === 'In-Progress' ? 'font-color-progress-status' : ''}${progressDataElement.process_status === 'Failure' ? 'font-color-failure-status' : ''}`}>{progressDataElement.process_status == 'Failure' ? <span>{progressDataElement.process_status}-{progressDataElement.message}</span> : <span>{progressDataElement.process_status}</span>}
                                                </p>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ol>
                        </div >
                    </div>
                </div>
                <div>
                    <div className="progress-wrapper-box">
                        <div className="contents-container"  >
                            <h2 className="font-md font-color-grey">To believe in the power of AI</h2>
                            <p className="font-exsm">Even before we were an AI company, we were a people-first technology company. Our vision is to be an agent and a catalyst that takes technology to every corner of the world. We predict that the biggest problem for enterprises in the age of AI would not be the disruptive nature of the technology but rather an inadequate vision for it, not allowing them to harness AI to its fullest.<br /><br />
                                As our team achieves new milestones every day at MEI INC, we couldn’t help but wonder how fast the world would think and act if it was powered by a truly intelligent conversational AI. A future built on such a powerful piece of technology would set the stage for holistic growth for not just the people and brands, but also increase the quality of life for all- shifting focus toward crucial matters in hand.
                                <br /><br />
                                As a start, MEI AI INC aims to empower brands and enterprises with Retrieval-Augmented Generation, democratizing their crucial industry and business insights across the organization.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="progress-wrapper-box">
                        <div className="contents-container"  >
                            <h2 className="font-md font-color-grey">We are powered by cutting-edge technology and intuitive people</h2>
                            <p className="font-exsm">Our team believes in the power of absolute research and technology, helping them stay ahead of the market trends, and also fit a huge concept like AI inside a not-so-huge container- product. This makes our platform instinctively ready to cater to the unique needs of our clients. Our goal is to not compete with models from Open AI, Gemini, or any other catering to the masses. The purpose of MEI is to bring all the bleeding-edge technologies to a single place, allowing people and enterprises to truly leverage the benefits of multi stack LLMs. <br /><br />Our team is diverse at its core- in culture, ideas, and skills. This makes us and our platform more inclusive, catering to the global audience. From Artists to Data Architects to ML Engineers, we all bring together a piece of ourselves into what we do, contributing to the overall evolution of technology, AI, and humanity. </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="progress-wrapper-box">
                        <div className="contents-container"  >
                            <h2 className="font-md font-color-grey">Leadership</h2>
                            <h2 className="font-md font-color-grey leadership-title">Madhu Kesavan</h2>
                            <p className="font-exsm">Driven by a relentless pursuit of innovation, Madhu is currently at the helm of an exciting new venture— MEI AI, poised to revolutionize the landscape of intelligent technology. The focus lies in crafting domain-specific LLMs (Language Model Models), tailored to empower mid-sized enterprises with transformative advantages. Actively engaged in networking events across key hubs such as California, Texas, UAE, and India, Madhu is deeply committed to fostering collaborative partnerships and driving meaningful industry advancements.</p>
                            <h2 className="font-md font-color-grey">Innovation through Collaboration</h2>
                            <p className="font-exsm">Innovation is the by-product when great minds come together to solve the most complex problems in the world. At least, that’s what we believe in Mei AI INC. We create a culture where creativity and ideas can flourish, pushing the boundaries of what’s possible through sheer spirit and collaboration. Because we believe that the future is not a destination, but a journey we embark on.<br /><br />If you believe in the sheer power that moves people in the face of adversity and are passionate about what you do, feel free to drop us an email.</p>
                        </div>
                    </div>
                </div>
            </Slider>
        </section >
    </>
}

export default DataProgress;