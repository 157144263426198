import { PayloadAction, createSlice } from "@reduxjs/toolkit";

/**Modals */
/**Login sign up model */
export interface historyUpdate {
  historyUpdate: boolean;
}

export interface isLoading {
  isLoading: boolean;
}

export interface isChatButton {
  isChatButton: boolean;
}

export interface isChatButtonEnable {
  isChatButtonEnable: boolean;
}
/**Initial state */
/**Login signUp dialog */
const historyUpdateState: historyUpdate = {
  historyUpdate: false,
};


const isLoadingState: isLoading = {
  isLoading: false
}

const isChatButtonState: isChatButton = {
  isChatButton: false
}

const isChatButtonEnableState: isChatButtonEnable = {
  isChatButtonEnable: false
}
/**Login Sign Up dialog */
export const historyUpdateSlice = createSlice({
  name: "historyUpdate",
  initialState: historyUpdateState,
  reducers: {
    sethistoryUpdateStatus: (state: any, action: PayloadAction<boolean>) => {
      state.historyUpdate = !action.payload;
    },
  },
});

export const loaderUpdateSlice = createSlice({
  name: "isLoading",
  initialState: isLoadingState,
  reducers: {
    setLoaderStatus: (state: any, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const chatButtonUpdateSlice = createSlice({
  name: "isChatButton",
  initialState: isChatButtonState,
  reducers: {
    setChatButtonStatus: (state: any, action: PayloadAction<boolean>) => {
      state.isChatButton = !action.payload;
    },
  },
});

export const IschatButtonEnableSlice = createSlice({
  name: "isChatButtonEnable",
  initialState: isChatButtonEnableState,
  reducers: {
    setisChatButtonEnableStatus: (state: any, action: PayloadAction<boolean>) => {
      state.isChatButtonEnable = !action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
/* export const { setLanguage } = languageSlice.actions;

export const { setProfileData } = profileData.actions;

export default languageSlice.reducer; */

export const historyUpdateReducer = historyUpdateSlice.reducer;
export const { sethistoryUpdateStatus } = historyUpdateSlice.actions;

export const loaderUpdateReducer = loaderUpdateSlice.reducer;
export const { setLoaderStatus } = loaderUpdateSlice.actions;


export const chatButtonReducer = chatButtonUpdateSlice.reducer;
export const { setChatButtonStatus } = chatButtonUpdateSlice.actions;

export const chatButtonEnableReducer = IschatButtonEnableSlice.reducer;
export const { setisChatButtonEnableStatus } = IschatButtonEnableSlice.actions;
