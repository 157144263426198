import "./App.scss";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import PrivateRoutesCheck from "./Utilities/Routes/PrivateRoutes";
import PublicRoutesCheck from "./Utilities/Routes/PublicRoutes";
import { ROUTES } from "./Constants/Constants";
import MeiDashboardLayout from "./Pages/MeiDashboardLayout/MeiDashboardLayout";
import DomainFields from "./Components/DomainFields/DomainFields";
import DataProgress from "./Components/DataProgress/DataProgress";
import ContentGeneratorLayout from "./Pages/ContentGeneratorLayout/ContentGeneratorLayout";
import ProgressStatus from "./Components/ProgressStatus/ProgressStatus";
import Spinner from "./Components/Spinner/Spinner";

function App() {
  /**Routes and related components */
  const routes: any = [
    {
      path: "",
      element: (
        <>
          <Navigate to={ROUTES.DOMAIN_FIELDS} />
        </>
      ),
    },
    {
      path: "",
      element: <PublicRoutesCheck />,
      children: [{ path: '', element: <MeiDashboardLayout />, children: [{ path: ROUTES.DOMAIN_FIELDS, element: <DomainFields /> }, { path: ROUTES.DATA_PROGRESS_STATUS, element: <ProgressStatus /> }] }]
    },
    {
      path: "",
      element: <PrivateRoutesCheck />,
      children: [{ path: '', element: <MeiDashboardLayout />, children: [{ path: ROUTES.DATA_PROGRESS, element: <DataProgress /> }] }, { path: ROUTES.CHAT_CONTENT, element: <ContentGeneratorLayout /> }, { path: ROUTES.CHAT_CONTENT_INITIAL, element: <ContentGeneratorLayout /> }]
    },
    {
      path: "*",
      element: (
        <>
          <Navigate to={ROUTES.DOMAIN_FIELDS} />
        </>
      ),
    },
  ];

  /**Router instance */
  const routers = createBrowserRouter(routes);

  return (
    <div>
      <Spinner ></Spinner>
      <RouterProvider router={routers} />
    </div>
  );
}

export default App;
