import "./MeiDashboardLayout.scss";
import domainArrows from "../../assets/global/domain-arrows.svg";
import { useEffect, useRef, useState } from "react";
import { axiosInstance } from '../../Utilities/Interceptor/Interceptor'
import { Outlet } from "react-router-dom";
import { SetSessionStorageData } from "../../Utilities/Helpers/Helper";
const MeiDashboardLayout = () => {
    const [linkListData, SetlinkListData] = useState([])
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageLoadData, SetimageLoadData] = useState([
        "https://storage.googleapis.com/meii-process-data/505a1eb3b6bf1e8465234077eec9b367%201.png",
        "https://www.sarkarinaukriexams.com/images/import/sne86811832.png",
        "https://www.sarkarinaukriexams.com/images/import/sne1586776004.png",
        "https://www.sarkarinaukriexams.com/images/import/sne20464172895.png",
    ])
    useEffect(() => {
        fetchListLinkData()
        switchImagesLoad(imageLoadData)
    }, [])

    const fetchListLinkData = async () => {
        axiosInstance.get('list_link').then((response) => {
            SetlinkListData(response.data?.data.weblinks)
            // SetimageLoadData(response.data?.data.image)
            // switchImagesLoad(response.data?.data.image)
        })
    }

    function switchImagesLoad(images: any) {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 15000);

        return () => clearInterval(interval);
    }




    return (
        <>
            <main className="domain-section-wrapper">

                <section className="col-12 col-lg-7 col-xl-8 domain-input-field-box">
                    <Outlet />
                </section>

                <section className="col-12 col-lg-5 col-xl-4 domain-cards-wrapper-box" style={{ backgroundImage: `url('${imageLoadData[currentImageIndex]}')` }}>
                    <div className="domain-cards-wrapper">
                        {linkListData && linkListData.map((data: any, index: any) =>
                            <div key={index} className="domain-cards-container">
                                <p className="domain-description font-exsm">{data.descriptions}</p>
                                <a href="#" className="domain-link-arrow">
                                    <p > {'“' + data.weblink + '”'}</p>
                                    <div className="domain-arrows-container">
                                        <img src={domainArrows} alt="" className="domain-arrows" />
                                    </div>
                                </a>
                            </div>
                        )}
                    </div>
                </section>
            </main >
        </>
    );
};

export default MeiDashboardLayout;
