import "./ChatWindow.scss";
import logo from "../../assets/global/meiLogoSmall.svg";
import dualStar from "../../assets/ChatHistory/dualStar.svg";
import meiLogoSmallColored from "../../assets/ChatHistory/meiLogoSmallColored.svg";
import userAvatar from "../../assets/ChatHistory/userAvatar.png";
import { useEffect, useMemo, useRef, useState } from "react";
import { axiosInstance } from '../../Utilities/Interceptor/Interceptor'
import { API_END_POINTS } from "../../Constants/Constants";
import LikeShareOptions from "../../Elements/LikeShareOptions/LikeShareOptions";
import { useParams } from "react-router-dom";
import { checkHistoryFlow, nullUndefinedEmptyCheck,nullUndefinedEmptySpaceCheck } from "../../Utilities/Helpers/Helper";
import { setChatButtonStatus, sethistoryUpdateStatus, setisChatButtonEnableStatus } from "../../Redux/Reducer/Reducer";
//import { socket } from '../../socket'
import { io } from "socket.io-client";
import Feedback from "../Feedback/Feedback";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";

const ChatWindow = () => {
    let { id } = useParams();
    const dispatch = useDispatch()
    const suggestionsCardsRef = useRef<any>(null)
    const discoverRef = useRef<any>(null)
    const [isChatStarted, setIsChatStarted] = useState(false);
    const [generateInitialContent, setGenerateInitialContent] = useState(false);
    const [getPredefinedQuestionData, setPredefinedQuestionData] = useState([]);
    const [getQueryValue, setQueryValue] = useState('');
    const [sugestionsCardHeight, setSuggestionsCardHeight] = useState(null);
    const [discoverHeight, setDiscoverHeight] = useState(null);
    const [clickedStrings, setClickedStrings] = useState<any>([]);
    const [getQueryValueForClick, setQueryValueForClick] = useState('');
    const [loadingStates, setLoadingStates] = useState<any>(false);
    useEffect(() => {
        setSuggestionsCardHeight(suggestionsCardsRef.current?.scrollHeight);
        setDiscoverHeight(discoverRef.current?.scrollHeight);
    }, [getPredefinedQuestionData, suggestionsCardsRef.current, discoverRef.current])
    const [getQueryTopicId, setQueryTopicId] = useState('')
    const [getTellmeLinkShow, setTellmeLinkShow] = useState(false)
    const [questionAnswer, setQuestionAnswer] = useState<any>([]);
    const [renderQuestAnswer, setrenderQuestAnswer] = useState<any>([]);
    const [isReloadActive, setIsReloadActive] = useState(false);
    const [getPrevQuestId, setPrevQuestId] = useState('')
    const inputRef = useRef<any>(null);
    const chatContainerRef = useRef<any>(null);
    const hasHistoryChangedData = useSelector((state: any) => {return state.chatButtonReducer.isChatButton })
    const socket = 'https://sse-stg.meii.ai';
   let eventSource:any
    // const socket = io('wss://meii-redis-socket-fzrkutbmyq-uc.a.run.app', {
    //     transports: ['websocket'], withCredentials: true,
    //     // reconnection: true, reconnectionAttempts: 20,
    //     // reconnectionDelay: 5000
    // })

    useEffect(() => {
        if (isChatStarted) {
            setTimeout(() => {
                setGenerateInitialContent(true);
            }, 1000);
            setrenderQuestAnswer([])
            setQueryTopicId('')
        }
    }, [isChatStarted])


    useEffect(() => {
       // console.log(getQueryValue,loadingStates,nullUndefinedEmptyCheck(getQueryValue))
        fetchPreDefinedQuestions()
    }, [])


    useEffect(() => {
        handleBackButton()
        dispatch(setChatButtonStatus(false))
    }, [hasHistoryChangedData])

    useEffect(() => {
        if (nullUndefinedEmptyCheck(id)) {
            fetchQuestionAnswerData(id);
            setIsChatStarted(true);
        } else {
            setIsChatStarted(false);

        }
    }, [id])

    // useEffect(() => {
    //     return () => {
    //         if (socket) {
    //             socket.disconnect();
    //             console.log('disconnected')
    //             if(getQueryTopicId) {
    //                 socket.emit('leave', getQueryTopicId);
    //             }
    //         }
    //     };
    // }, []);




    useEffect(() => {
        if (getQueryTopicId) {
                eventSource = new EventSource(`${socket}/subscribe/${getQueryTopicId}`);

               eventSource.onopen = () => {
                  console.log('EventSource connected successfully');
                };
               eventSource.onmessage = (event:any) => {
                  console.log(event,'event on receive')
                  getUpdateSocket(JSON.parse(event.data))
                };
           
                eventSource.onerror = (error:any) => {
                    setLoadingStates(false)
                  console.log('Error occurred:', error);
                 
                };
                return () => {
                   console.log('Error closed:');
                  eventSource.close();
                  setLoadingStates(false)
                };
  
         
            // socket.on('connect', () => {
            //     console.log('Connected to server', getQueryTopicId);
            //     socket.emit('join', getQueryTopicId);
            // });

            // socket.on('reconnect', () => {
            //     console.log('reconnected');
            //     socket.emit('updateSocketId', getQueryTopicId);
            //   });
           
        }
    }, [getQueryTopicId])



    function getUpdateSocket(socketData: any) {
        setLoadingStates(false)
        setIsReloadActive(false)
        setrenderQuestAnswer((prev: any) => [...prev, socketData.data])
        dispatch(sethistoryUpdateStatus(false))

        dispatch(setisChatButtonEnableStatus(false))
    }
    useEffect(() => {
        // Scroll down to the bottom of the chat container
        chatContainerRef.current?.scrollIntoView({ behavior: "smooth"}) 
      }, [renderQuestAnswer]);

    const fetchPreDefinedQuestions = async () => {
        await axiosInstance.get('prompt_question').then((response: any) => {
            setPredefinedQuestionData(response.data.data)
        })
    }
    const handleInputChange = (event: any) => {
        const newInputString = event.target.value;
        setQueryValue(newInputString);
        setQueryValueForClick(newInputString)
    };
    const checkMoreAnswer = (lastIndexid: any) => {
        setIsReloadActive(true);
        setLoadingStates(true);
        fetchApiTrigger(getQueryValueForClick, 0);
        //  }
    };



    const QueryAPITrigger = (getQueryString: any, from: any) => {
        setQueryValueForClick(getQueryString)
        fetchApiTrigger(getQueryString, 1)
        inputRef.current.blur();


    }

    const fetchApiTrigger = async (getQueryString: any, from: any) => {

        setLoadingStates(true);
        // console.log(loadingStates,'loading')
        let questionId = ''
        if (from == 0) {
            const questionData = renderQuestAnswer[renderQuestAnswer.length - 1]
            questionId = questionData.question_id
            setPrevQuestId(questionId)
        }
        let payload = {
            query: getQueryString,
            topic_id: getQueryTopicId,
            question_id: questionId
        }

        axiosInstance.post(`query?requery=${from == 0 ? true : false}`, payload).then((response) => {
            if (response?.data.status_code === API_END_POINTS.status_code) {
                setQueryValue('')
                setQueryTopicId(response?.data.data.topic)

                if (from === 1) {
                    setrenderQuestAnswer((prev: any) => [...prev, response?.data.data.query_data])
                } else {
                    setrenderQuestAnswer((prev: any) => [...prev])

                }

            }
        })
    }
    const handleKeyPress = (e: any) => {
        if (e.code === "Enter" && nullUndefinedEmptySpaceCheck(getQueryValue) && !loadingStates) {
            const newInputString = e.target.value;
            setQueryValue(newInputString);
            setIsChatStarted(true);
            QueryAPITrigger(getQueryValue, 1)
            inputRef.current.blur();
        }
    };
    const fetchQuestionAnswerData = async (topicId: any) => {
        await axiosInstance.get(`detail_topic?topic_id=${topicId}`).then((response: any) => {
            setQuestionAnswer(response.data.data);
        })
    }
    const decryptData = (text: any) => {
        const secretPass = "XkhZG4fW2t2W";
        const bytes = CryptoJS.AES.decrypt(text, secretPass);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };

    const memoizedComponent = useMemo(() => {
        return <>
            {questionAnswer && questionAnswer.length > 0 ?
                (questionAnswer.map((questionDataElement: any, index: any) => {
                    return (
                        <div key={index}>
                            <div className="user-query-wrapper d-flex">
                                <img src={userAvatar} alt="" className="user-avatar" />
                                <p className="user-query font-color-extra-light-grey bg-dark-grey font-exsm">{questionDataElement.question}</p>
                            </div>
                            {questionDataElement.answers.map((answerDataElement: any, index: any) => {
                                return <div key={index}>
                                    <div className="d-flex user-answer-wrapper">
                                        <img src={meiLogoSmallColored} alt="" className="user-avatar" />
                                        {answerDataElement.is_html == false ?
                                            <p className="font-color-extra-light-grey font-exsm whitespace">{answerDataElement.answer}</p>
                                            :<p className="font-color-extra-light-grey font-exsm whitespace" 
                                        dangerouslySetInnerHTML={{
                                          __html: answerDataElement.answer
                                        }}
                                      />
                                    }
                                    </div>
                                    <div className="like-options-box d-flex">
                                        <div className="user-avatar" />
                                        <LikeShareOptions answerId={answerDataElement.answer_id} likeUnlike={answerDataElement.feedback_status} historyflow={true} />
                                    </div>
                                </div>
                            })}</div>)
                })) : null}
        </>
    }, [questionAnswer]);

    const socketRenderComponent = useMemo(() => {
        return <>
      
            {renderQuestAnswer && renderQuestAnswer.length > 0 ?
                (renderQuestAnswer.map((questionDataElement: any, index: any) => {
                    return (
                        <>
                        <div key={index}>
                            {questionDataElement.is_question === true ?
                            <>
                                <div className="user-query-wrapper d-flex">
                                    <img src={userAvatar} alt="" className="user-avatar" />
                                    <p className="user-query font-color-extra-light-grey bg-dark-grey font-exsm">{questionDataElement.message}</p>
                                </div> <div ref={chatContainerRef}></div></>: <>
                                <div ref={chatContainerRef}></div>
                                    <div className="d-flex user-answer-wrapper">
                                        <img src={meiLogoSmallColored} alt="" className="user-avatar" />
                                        {/* {questionDataElement.message ? */}
                                        {questionDataElement.is_html === false ?
                                        <p className="font-color-extra-light-grey font-exsm whitespace" >{questionDataElement.message}</p> :  <p className="font-color-extra-light-grey font-exsm whitespace" 
                                        dangerouslySetInnerHTML={{
                                          __html: questionDataElement.message
                                        }}
                                      />}
                                        {/* <p ></p> */}
                                        {/* } */}
                                    </div>
                                    <div className="like-options-box d-flex">
                                        <div className="user-avatar" ></div>
                                        <LikeShareOptions answerId={questionDataElement.answer_id} historyflow={false}  />
                                    </div>
                                </>
                            }
                        </div> </>)
                })) : null}
               
            {/* {console.log(loadingStates, 'loadingStates[index]')} */}
            {loadingStates &&
                <div className="d-flex user-answer-wrapper">
                    <img src={meiLogoSmallColored} alt="" className="user-avatar" />
                    <div className="dot-shuttle-contianer">
                        <div className="dot-shuttle"></div>
                    </div>
                </div>
            }
        </>
    }, [renderQuestAnswer]);

    const handleBackButton = () => {
        if (isChatStarted) {
            // setIsChatStarted((prevValue: any) => { return !prevValue });
            setIsChatStarted(false)
            setrenderQuestAnswer([])
            setQueryTopicId('')
            dispatch(setisChatButtonEnableStatus(true))
            dispatch(sethistoryUpdateStatus(true))
            if(eventSource){
                eventSource.close();
            }
        }
        else {
            return
        }
    }

    return <>
        <section className={`chat-window-wrapper bg-light-grey ${isChatStarted ? 'chat-screen' : 'suggestion-screen'}`}>
            {!nullUndefinedEmptyCheck(id) ? <>
                <div className="dummy-content"></div>
                <button type="button" className="back-button-wrapper font-exsm position-relative font-color-extra-light-grey" onClick={handleBackButton}>Back</button>
                <div className="chat-window-container">
                    <div ref={suggestionsCardsRef} style={{ maxHeight: isChatStarted ? '0px' : `${sugestionsCardHeight}px` }} className="row row-cols-1 row-cols-md-2 row-cols-lg-3 suggestions-cards-wrapper">
                        {getPredefinedQuestionData && getPredefinedQuestionData.length ?
                            (<>
                                {getPredefinedQuestionData.map((index: any) =>
                                    <div key={index} className="flex-grow-1 py-1">
                                        <div className="suggestions-card-container d-flex align-items-center bg-dark-grey-less-opacity cursorshow" onClick={() => { setIsChatStarted(true); QueryAPITrigger(index, 0) }}>
                                            <img src={logo} alt="" className="suggestion-icons" />
                                            <p className="suggestion-description font-color-white font-thin">{index}</p>
                                        </div>
                                    </div>
                                )}
                            </>) : null}
                    </div> 
                   
                    <div className="d-flex"><div className="chat-input-field-wrapper bg-dark-grey-less-opacity position-relative d-flex align-items-center w-100">
                        <input autoCorrect="off" autoComplete="off" ref={inputRef} type="text" id="queryInput" className={`query-input-field w-100   ${isChatStarted ? 'bg-dark-grey' : 'font-color-extra-light-grey'}`} name="query" disabled={loadingStates} value={getQueryValue} onKeyDown={handleKeyPress} onChange={handleInputChange} />
                        <button type="submit" className={`search-query-button bg-light-grey position-absolute ${(loadingStates || !nullUndefinedEmptySpaceCheck(getQueryValue)) ? 'regenerate-disable ' : 'cursorshow'}`} disabled={(loadingStates || !nullUndefinedEmptySpaceCheck(getQueryValue))} onClick={() => { setIsChatStarted(true); QueryAPITrigger(getQueryValue, 1) }}></button>
                    </div>
                    </div>

                    <h1 id='discover-descriptiondata' ref={discoverRef} style={{ maxHeight: isChatStarted ? '0px' : `${discoverHeight}px` }} className="discover-description font-color-white font-xl">Your data is ready{' '}<img src={dualStar} alt="" className="dual-start" />{' '}Discover insights that inspires you.</h1>
                </div>
                <div className="dummy-content"></div>
            </>
                : null}

            <div className="chat-screen-contents-box">
                <div className={`user-query-container chat-screen-contents ${nullUndefinedEmptyCheck(id) ? 'history-chat' : 'live-chat'}`}>
                    {nullUndefinedEmptyCheck(id) ? (memoizedComponent) : (socketRenderComponent)}


{renderQuestAnswer && renderQuestAnswer.length > 1 && renderQuestAnswer[renderQuestAnswer.length - 1].available == "true" ?
                    <div className="chat-screen-contents tell-me-more-box">
                        {!nullUndefinedEmptyCheck(id) && renderQuestAnswer && renderQuestAnswer.length > 1 &&
                            <div className="d-flex justify-content-center">
                                <button type="button" disabled={renderQuestAnswer[renderQuestAnswer.length - 1].question_id == getPrevQuestId ? true : false} className={`tell-me-more-wrapper font-color-extra-light-grey position-relative bg-dark-grey font-exsm ${isReloadActive ? 'reload-active' : ''} ${renderQuestAnswer[renderQuestAnswer.length - 1].question_id == getPrevQuestId ? 'regenerate-disable' : ''}`} onClick={() => { checkMoreAnswer(getQueryValueForClick) }} >Tell me more about it</button>
                            </div>}

                    </div> : null
}



                </div>
            </div>
        </section>
    </>
}

export default ChatWindow;