import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { GetSessionStorageData } from "../Helpers/Helper";
import {
  AUTHENTICATION,
  ROUTES,
} from "../../Constants/Constants";
import { useDispatch } from "react-redux";

const PrivateRoutesCheck = () => {
  const dispatch = useDispatch();

  /**Get access token from session storage */
  const token = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);

  const tokenCheck = (token: any) => {
    if (token) {
      return (
        <>
          <Outlet />
        </>
      );
    } else {
      return (
        <>
          <Navigate to={ROUTES.DOMAIN_FIELDS} />
        </>
      );
    }
  };

  return tokenCheck(token);
};

export default PrivateRoutesCheck;
