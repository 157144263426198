import React, { useEffect } from "react";
import "./Spinner.scss";
import { useSelector } from "react-redux";
import meiLogoColored from "../../assets/ChatHistory/meiLogoSmallColored.svg";

const Spinner = () => {

    const hasLoadingChanged = useSelector((state: any) => { return state.loaderUpdateReducer.isLoading })

    useEffect(() => {
        console.log(hasLoadingChanged)
    }, [hasLoadingChanged])

    return <>
        {
            hasLoadingChanged &&
            <div className="mei-logo-spinner-wrapper">
                <div className="loader-overlay"></div>
                <div className="mei-logo-spinner-container position-absolutes">
                    <img src={meiLogoColored} alt='' className="mei-logo-spinner" />
                </div>
            </div>
        }
    </>
}

export default Spinner;