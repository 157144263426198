/**Routes */
export const ROUTES: any = {
  DOMAIN_FIELDS: "/get-started",
  DATA_PROGRESS: "/progress",
  CHAT_CONTENT: "/generate-content/:id",
  CHAT_CONTENT_INITIAL: "/generate-content",
  DATA_PROGRESS_STATUS:"/progressstatus/:id"
};

/**API end points */
export const API_END_POINTS: any = {
  status_code: 200
};

/**Authentication pages constants*/
export const AUTHENTICATION: any = {
  SHARED: {
    ACCESS_TOKEN: "accessToken",
    REFRESH_TOKEN: "refreshToken",
    PROFILE_PICTURE: "profilePicture",
    NAME: "name",
    EMAIL: "email",
    ACCOUNT_TYPE: "accountType",
    STATUS: "status",
    TRUE: true,

  }
};
export const searchHistoryData: any = [{ 'title': 'www.heinekin.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.swiggy.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.amazon.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.heinekin.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.swiggy.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.amazon.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.heinekin.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.swiggy.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.amazon.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.heinekin.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.swiggy.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.amazon.com', 'desc': 'Try it out our products llm Ask the question from the website' }];

export const backgroundimage: any = [
  '/assets/DomainProgress/domain-bg.jpg'
];


export const progressData = [
  { label: 'Collecting Links', status: 'completed' },
  { label: 'Crawling text from the links', status: 'in progress' },
  { label: 'Indexing the crawled text', status: 'pending' },
  { label: 'embedding model', status: 'pending' },
  { label: 'Finishing it up!', status: 'pending' },
]


export const chatHistoryData = [
  { label: 'Today', title: ['Hi, Tell me a story'] },
  { label: 'Yesterday', title: ['Hi, Tell me a story', 'Hi, Tell me a story', 'Hi, Tell me a story', 'Hi, Tell me a story', 'Hi, Tell me a story'] },
]


export const tryLinks = ['Apple', 'Crypto', 'NFT', 'AI', 'Figma', 'Foundation', 'Web3 platform'];

export const feedbackChipLabel = [{ label: 'Offensive / Unsafe', id: 1 }, { label: 'Not factually correct', id: 2 }, { label: 'Other', id: 3 }];